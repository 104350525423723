import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { getKeywords, addKeyword, updateKeywordCount, removeKeyword, updateKeyword, clearAllKeywords } from '../utils/userManager';
import { useSubscriptionLimits } from '../hooks/useSubscriptionLimits';
import SubscriptionStatus from '../components/subscription/SubscriptionStatus';
import SubscriptionPlans from '../components/subscription/SubscriptionPlans';
import { FaCrown, FaTag, FaPlus, FaTimes, FaGlobe, FaGoogle } from 'react-icons/fa';
import { Link, useLocation } from 'react-router-dom';
import KeywordManager from '../components/keywords/KeywordManager';

function ProfilePage() {
  const { user, loginWithGoogle } = useAuth();
  const location = useLocation();
  const { isWithinLimits, limits } = useSubscriptionLimits();
  const [keywords, setKeywords] = useState({ selected: [], recommended: [] });
  const [activeTab, setActiveTab] = useState(
    location.state?.activeTab || 'keywords'
  );
  const [error, setError] = useState(null);
  const [hasChanges, setHasChanges] = useState(false);

  const loadUserKeywords = async () => {
    if (user) {
      try {
        const userKeywords = await getKeywords(user.uid);
        setKeywords({
          selected: userKeywords || [],
          recommended: []
        });
        setError(null);
      } catch (error) {
        console.error('키워드 로드 중 오류:', error);
        setError('키워드를 불러오는 중 오류가 발생했습니다.');
      }
    }
  };

  useEffect(() => {
    loadUserKeywords();
  }, [user]);

  const handleAddKeyword = async (newKeyword) => {
    if (!isWithinLimits.keywords(keywords.selected.length + 1)) {
      alert(`키워드는 최대 ${limits.keywords}개까지만 등록할 수 있습니다.`);
      return;
    }
    
    try {
      // 언어 감지하여 country 설정
      const isKorean = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/.test(newKeyword.text);
      const country = isKorean ? 'kr' : 'us';
      
      const keywordToAdd = {
        ...newKeyword,
        id: Date.now().toString(),
        country,
        createdAt: new Date().toISOString(),
        isEditable: false // 수정 불가능하도록 설정
      };
      
      setKeywords(prev => ({
        ...prev,
        selected: [...prev.selected, keywordToAdd]
      }));
      setHasChanges(true);
      setError(null);
    } catch (error) {
      console.error('키워드 추가 중 오류:', error);
      setError('키워드 추가 중 오류가 발생했습니다.');
    }
  };

  const handleRemoveKeyword = (id) => {
    setKeywords(prev => ({
      ...prev,
      selected: prev.selected.filter(k => k.id !== id)
    }));
    setHasChanges(true);
    setError(null);
  };

  const handleSave = async () => {
    try {
      await clearAllKeywords(user.uid);
      await Promise.all(keywords.selected.map(keyword => 
        addKeyword(user.uid, keyword)
      ));
      await updateKeywordCount(user.uid, keywords.selected.length);
      setHasChanges(false);
      alert('키워드가 저장되었습니다.');
    } catch (error) {
      console.error('키워드 저장 중 오류:', error);
      setError('키워드 저장 중 오류가 발생했습니다.');
    }
  };

  const handleClearAll = async () => {
    if (!window.confirm('모든 키워드를 삭제하시겠습니까?')) return;
    
    try {
      await clearAllKeywords(user.uid);
      setKeywords({ selected: [], recommended: [] });
      await loadUserKeywords();
    } catch (error) {
      console.error('키워드 전체 삭제 중 오류:', error);
      setError('키워드 전체 삭제 중 오류가 발생했습니다.');
    }
  };

  const renderLoginRequired = () => (
    <div className="max-w-md mx-auto mt-20 p-6 bg-white rounded-lg shadow-md text-center">
      <h2 className="text-2xl font-bold mb-4">Login Required</h2>
      <p className="text-gray-600 mb-6">
        Please sign in to manage your keywords and subscription.
      </p>
      <button
        onClick={loginWithGoogle}
        className="flex items-center justify-center gap-2 w-full px-4 py-2 bg-white border border-gray-300 rounded-lg text-gray-700 hover:bg-gray-50 transition-colors"
      >
        <FaGoogle />
        Sign in with Google
      </button>
    </div>
  );

  const renderMainContent = () => (
    <div className="max-w-4xl mx-auto p-4">
      {error && (
        <div className="bg-red-50 text-red-600 p-4 rounded-lg mb-4">
          {error.replace('키워드', 'keyword').replace('오류', 'error').replace('불러오는', 'loading').replace('발생했습니다', 'occurred')}
        </div>
      )}
      
      <div className="flex items-center justify-between mb-6 border-b">
        <div className="flex gap-4">
          <button
            onClick={() => setActiveTab('keywords')}
            className={`text-lg font-semibold pb-2 border-b-2 ${
              activeTab === 'keywords'
                ? 'text-blue-600 border-blue-600'
                : 'text-gray-500 border-transparent hover:text-gray-700'
            }`}
          >
            <div className="flex items-center justify-center gap-2">
              <FaTag />
              Keywords
            </div>
          </button>
          <button
            onClick={() => setActiveTab('subscription')}
            className={`text-lg font-semibold pb-2 border-b-2 ${
              activeTab === 'subscription'
                ? 'text-blue-600 border-blue-600'
                : 'text-gray-500 border-transparent hover:text-gray-700'
            }`}
          >
            <div className="flex items-center justify-center gap-2">
              <FaCrown />
              Subscription
            </div>
          </button>
        </div>
      </div>

      {activeTab === 'keywords' ? (
        <KeywordManager
          keywords={keywords}
          onAddKeyword={handleAddKeyword}
          onRemoveKeyword={handleRemoveKeyword}
          onSave={handleSave}
          onClearAll={handleClearAll}
          hasChanges={hasChanges}
          onUpgrade={() => setActiveTab('subscription')}
        />
      ) : (
        <>
          <SubscriptionStatus />
          <SubscriptionPlans />
        </>
      )}
    </div>
  );

  return user ? renderMainContent() : renderLoginRequired();
}

export default ProfilePage; 