import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth, GoogleAuthProvider } from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyC-3j6gQLU4u9LKtY6ZjQjIZggCCRTGLtc",
  authDomain: "ai-news-feed-d8ef9.firebaseapp.com",
  projectId: "ai-news-feed-d8ef9",
  storageBucket: "ai-news-feed-d8ef9.firebasestorage.app",
  messagingSenderId: "500587906870",
  appId: "1:500587906870:web:9b708c3035d5a0cf561597",
  measurementId: "G-EVK5B85GPM"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);

const googleProvider = new GoogleAuthProvider();
googleProvider.setCustomParameters({
  prompt: 'select_account'
});

export { db, auth, googleProvider };
