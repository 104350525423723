import { db } from '../firebase';
import { doc, updateDoc, serverTimestamp, getDoc, deleteField } from 'firebase/firestore';

// 구독 상태 업데이트
export async function updateSubscription(uid, subscriptionData) {
  const userRef = doc(db, 'users', uid);
  const userSnap = await getDoc(userRef);
  const userData = userSnap.data();
  
  // 현재 사용량 데이터 유지
  const currentUsage = userData?.usage || {
    aiAnalysisCount: 0,
    lastResetDate: serverTimestamp(),
    keywordCount: 0,
    historyCount: 0
  };

  await updateDoc(userRef, {
    'subscription': subscriptionData,
    'tier': subscriptionData.status === 'active' ? 'premium' : 'free',
    'usage': currentUsage, // 기존 usage 데이터 유지
    updatedAt: serverTimestamp()
  });
}

// 구독 취소
export async function cancelSubscription(uid) {
  const userRef = doc(db, 'users', uid);
  const userSnap = await getDoc(userRef);
  const userData = userSnap.data();
  
  // 현재 사용량 데이터 유지
  const currentUsage = userData?.usage || {
    aiAnalysisCount: 0,
    lastResetDate: serverTimestamp(),
    keywordCount: 0,
    historyCount: 0
  };

  await updateDoc(userRef, {
    'subscription.status': 'cancelled',
    'subscription.cancelDate': serverTimestamp(),
    'tier': 'free',
    'usage': currentUsage, // 기존 usage 데이터 유지
    updatedAt: serverTimestamp()
  });
}

// 구독 만료 체크
export function checkSubscriptionExpiry(subscription) {
  if (!subscription || !subscription.endDate) return true;
  
  const now = new Date();
  const endDate = subscription.endDate.toDate();
  
  return now > endDate;
}

export async function checkAndUpdateSubscriptionStatus(uid) {
  const userData = await getUserData(uid);
  if (!userData) return;

  const subscription = userData.subscription;
  if (!subscription || !subscription.endDate) return;

  const now = new Date();
  const endDate = subscription.endDate.toDate();

  if (now > endDate && userData.tier === 'premium') {
    await updateDoc(doc(db, 'users', uid), {
      tier: 'free',
      'subscription.status': 'expired',
      updatedAt: serverTimestamp()
    });
    return true;
  }
  return false;
}

export async function migrateUserSubscription(uid) {
  const userRef = doc(db, 'users', uid);
  const userSnap = await getDoc(userRef);
  const userData = userSnap.data();

  if (!userData) return;

  // premiumUntil이 있고 subscription 객체가 없거나 불완전한 경우
  if (userData.premiumUntil && (!userData.subscription || !userData.subscription.endDate)) {
    const subscriptionData = {
      status: 'active',
      startDate: userData.updatedAt ? new Date(userData.updatedAt) : serverTimestamp(),
      endDate: new Date(userData.premiumUntil),
      paymentMethod: 'free_trial',
      cancelDate: null,
      plan: 'monthly'
    };

    await updateDoc(userRef, {
      subscription: subscriptionData,
      // premiumUntil 필드는 이제 subscription으로 대체되므로 삭제
      premiumUntil: deleteField()
    });
  }
} 