import { useAuth } from '../../../contexts/AuthContext';
import { FaTimes, FaLock } from 'react-icons/fa';
import { ANALYSIS_OPTIONS } from '../../../utils/newsAnalyzer';
import { useSubscriptionLimits } from '../../../hooks/useSubscriptionLimits';

function AnalysisOptionsModal({ isOpen, onClose, onAnalyze, analysisOption, setAnalysisOption, totalNewsCount }) {
  const { user } = useAuth();
  const { limits, tier, usage } = useSubscriptionLimits();
  
  const remainingAnalysis = limits.analysisPerMonth - (usage.aiAnalysisCount || 0);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
      <div className="bg-white rounded-lg w-full max-w-md">
        <div className="p-6">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-xl font-semibold">AI Analysis Options</h2>
            <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
              <FaTimes />
            </button>
          </div>
          
          <div className="mb-4 text-sm text-gray-600">
            Select the number of news articles to analyze from {totalNewsCount} total articles.
          </div>

          <div className="space-y-2">
            {Object.entries(ANALYSIS_OPTIONS).map(([key, option]) => (
              <button
                key={key}
                onClick={() => {
                  if (!option.requiresAuth || user) {
                    setAnalysisOption(key);
                  }
                }}
                disabled={option.requiresAuth && !user || (user && tier === 'free' && option.requiresAuth)}
                className={`w-full px-4 py-3 rounded-lg flex items-center justify-between ${
                  analysisOption === key
                    ? 'bg-blue-50 text-blue-600 border-2 border-blue-500'
                    : 'border hover:bg-gray-50'
                } ${(option.requiresAuth && !user) || (user && tier === 'free' && option.requiresAuth) ? 'opacity-50 cursor-not-allowed' : ''}`}
              >
                <span>{option.label}</span>
                {((option.requiresAuth && !user) || (user && tier === 'free' && option.requiresAuth)) && (
                  <div className="flex items-center gap-2 text-gray-500">
                    <FaLock className="w-4 h-4" />
                    <span className="text-sm">
                      {!user ? '로그인 필요' : '프리미엄 전용'}
                    </span>
                  </div>
                )}
              </button>
            ))}
          </div>

          {user && tier === 'free' && (
            <div className="mt-4 p-3 bg-blue-50 rounded-lg text-sm text-blue-600">
              💡 프리미엄으로 업그레이드하면 더 많은 뉴스를 한 번에 분석할 수 있습니다.
            </div>
          )}
        </div>
        <div className="border-t p-4 flex justify-end gap-2">
          <button
            onClick={onClose}
            className="px-4 py-2 text-gray-600 hover:bg-gray-50 rounded-lg transition-colors"
          >
            Cancel
          </button>
          <button
            onClick={onAnalyze}
            className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors"
          >
            Start
          </button>
        </div>
      </div>
    </div>
  );
}

export default AnalysisOptionsModal; 