import React, { useEffect } from 'react';
import { FaTimes } from 'react-icons/fa';

function TopicNewsModal({ topic, newsList, formatDate, onClose }) {
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);

  return (
    <div 
      className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4"
      onClick={(e) => {
        if (e.target === e.currentTarget) onClose();
      }}
    >
      <div 
        className="bg-white rounded-lg w-full max-w-2xl max-h-[80vh] flex flex-col"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="p-6 border-b flex-shrink-0">
          <div className="flex justify-between items-center">
            <h2 className="text-xl font-semibold text-gray-800">
              {topic}
              <span className="text-sm text-gray-500 ml-2">
                ({newsList.length})
              </span>
            </h2>
            <button
              onClick={onClose}
              className="text-gray-500 hover:text-gray-700"
            >
              <FaTimes />
            </button>
          </div>
        </div>
        <div className="overflow-y-auto flex-grow p-6 space-y-4">
          {newsList.map((news, idx) => (
            <a
              key={idx}
              href={news.link}
              target="_blank"
              rel="noopener noreferrer"
              className="block border border-gray-100 rounded-lg p-4 hover:bg-gray-50 transition-colors duration-200"
            >
              <h3 className="text-gray-800 font-medium mb-2">
                {news.title}
              </h3>
            </a>
          ))}
        </div>
      </div>
    </div>
  );
}

export default TopicNewsModal; 