import { useState, useEffect, useMemo } from 'react';
import { FaNewspaper, FaSync } from 'react-icons/fa';
import stringSimilarity from 'string-similarity';
import NewsTabNavigation from '../components/news/NewsTabNavigation';
import NewsList from '../components/news/NewsList';
import KeywordFilterModal from '../components/news/KeywordFilterModal';
import AINewsSection from '../components/news/AINewsSection';
import LoadingSpinner from '../components/common/LoadingSpinner';
import EmptyState from '../components/common/EmptyState';
import Pagination from '../components/common/Pagination';
import { useAuth } from '../contexts/AuthContext';
import { getKeywords, incrementAiAnalysisCount } from '../utils/userManager';
import { useNavigate } from 'react-router-dom';
import { useSubscriptionLimits } from '../hooks/useSubscriptionLimits';
import { getAnalysisHistory } from '../utils/localStorageManager';
import { logNewsInteraction } from '../utils/analytics';

const FETCH_NEWS_URL = 'https://fetchnewsfeeds-irpjaw2bta-uc.a.run.app/fetchNewsFeeds';

function NewsFeed() {
  const { user, userData } = useAuth();
  const navigate = useNavigate();
  const [news, setNews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activeKeywords, setActiveKeywords] = useState([]);
  const [keywords, setKeywords] = useState({ selected: [], recommended: [] });
  const [isKeywordModalOpen, setIsKeywordModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [lastUpdated, setLastUpdated] = useState(null);
  const [showAnalysisModal, setShowAnalysisModal] = useState(false);
  const [showHistoryModal, setShowHistoryModal] = useState(false);
  const [analysisHistory, setAnalysisHistory] = useState([]);
  const [activeTab, setActiveTab] = useState('latest');
  const [error, setError] = useState(null);
  const { limits, usage } = useSubscriptionLimits();
  const [initialLoading, setInitialLoading] = useState(true);  // 초기 로딩 상태 추가
  const LOADING_DELAY = 3000; // 3초 딜레이

  // 캐시 관련 상수 추가
  const CACHE_KEY = 'newsCache';
  const CACHE_DURATION = 15 * 60 * 1000; // 15분

  // 사용량 제한 체크
  const isAnalysisLimitReached = usage.aiAnalysisCount >= limits.analysisPerMonth;

  // 키워드 초기화
  useEffect(() => {
    const loadUserKeywords = async () => {
      if (user) {
        try {
          const userKeywords = await getKeywords(user.uid);
          setKeywords({
            selected: userKeywords || [],
            recommended: []
          });
          setActiveKeywords(userKeywords || []);
        } catch (error) {
          console.error('키워드 로드 중 오류:', error);
          // Local Storage 폴백 제거하고 빈 배열로 초기화
          setKeywords({ selected: [], recommended: [] });
          setActiveKeywords([]);
        }
      } else {
        // 비로그인 사용자는 빈 배열로 초기화
        setKeywords({ selected: [], recommended: [] });
        setActiveKeywords([]);
      }
    };
    
    loadUserKeywords();
  }, [user]);

  const retryFetch = async (url, options, maxRetries = 3) => {
    for (let i = 0; i < maxRetries; i++) {
      try {
        const response = await fetch(url, options);
        if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
        return response;
      } catch (error) {
        if (i === maxRetries - 1) throw error;
        // 재시도 전 대기 시간 증가
        await new Promise(resolve => setTimeout(resolve, 1000 * (i + 1)));
      }
    }
  };

  const fetchNews = async () => {
    logNewsInteraction('Fetch', 'News Feed Refresh');
    setLoading(true);
    setError(null);
    let newsData = [];

    try {
      const response = await retryFetch(FETCH_NEWS_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        mode: 'cors',
        body: JSON.stringify({
          keywords: keywords.selected
        })
      }, 5);

      const data = await response.json();

      if (data.error) {
        throw new Error(data.error);
      }

      newsData = processNews(data.results.flatMap(result => {
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(result.xmlText, 'text/xml');
        const items = xmlDoc.querySelectorAll('item');
        
        return Array.from(items).map(item => ({
          title: item.querySelector('title')?.textContent || '',
          link: item.querySelector('link')?.textContent || '',
          pubDate: item.querySelector('pubDate')?.textContent || '',
          keyword: result.keyword.text
        }));
      }));

      // 캐시 저장
      localStorage.setItem(CACHE_KEY, JSON.stringify({
        timestamp: new Date().getTime(),
        data: newsData
      }));

      setNews(newsData);
      setLastUpdated(new Date().toLocaleString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: true
      }));
      
      // 초기 로딩 상태를 지연 후 해제
      setTimeout(() => {
        setInitialLoading(false);
        setLoading(false);
      }, LOADING_DELAY);

    } catch (error) {
      console.error('뉴스 로드 중 상세 오류:', error);
      setError('뉴스를 불러오는데 실패했습니다.');
      setNews([]);
      
      setTimeout(() => {
        setInitialLoading(false);
        setLoading(false);
      }, LOADING_DELAY);
    }
  };

  // useEffect에서는 fetchNews 함수를 직접 호출
  useEffect(() => {
    fetchNews();
  }, [keywords.selected]);

  // 뉴스 카운트 계산
  const newsCountByKeyword = useMemo(() => {
    const counts = {};
    news.forEach(item => {
      item.keywords.forEach(keyword => {
        counts[keyword] = (counts[keyword] || 0) + 1;
      });
    });
    return counts;
  }, [news]);

  // 뉴스 중복 제거 및 키워드 병합 함수
  const processNews = (newsArray) => {
    const newsMap = new Map();
    
    newsArray.forEach(news => {
      let isDuplicate = false;
      
      // 제목에서 출처 추출 (마지막 하이픈 이후 부분)
      const titleParts = news.title.split(' - ');
      const title = titleParts.slice(0, -1).join(' - ').trim();
      const source = titleParts[titleParts.length - 1] || '';
      
      // 이미 처리된 뉴스들과 유사도 비교
      for (const [_, existingNews] of newsMap) {
        const similarity = stringSimilarity.compareTwoStrings(
          existingNews.title.toLowerCase(),
          title.toLowerCase()
        );
        
        if (similarity > 0.8) {  // 80% 이상 유사하면 중복으로 처리
          isDuplicate = true;
          // 기존 뉴스의 키워드 배열에 현재 뉴스의 키워드 추가
          existingNews.keywords = [...new Set([...existingNews.keywords, news.keyword])];
          break;
        }
      }
      
      // 중복이 아닌 경우 새로운 뉴스로 추가
      if (!isDuplicate) {
        newsMap.set(news.link, {
          ...news,
          title: title,  // 출처를 제외한 제목
          source: source,  // 추출된 출처
          keywords: [news.keyword],
          parsedDate: new Date(news.pubDate)
        });
      }
    });
    
    // 날짜순으로 정렬하여 반환
    return Array.from(newsMap.values())
      .sort((a, b) => b.parsedDate - a.parsedDate);
  };

  // 필터링된 뉴스 계산 (중복 제거 추가)
  const filteredNews = useMemo(() => {
    if (activeKeywords.length === 0) return news;
    return news.filter(item =>
      item.keywords.some(keyword =>
        activeKeywords.some(ak => ak.text === keyword)
      )
    );
  }, [news, activeKeywords]);

  // 키워드 토글 처리
  const handleToggleKeyword = (keyword) => {
    if (!keyword) return;
    
    setActiveKeywords(prev => {
      const isActive = prev.some(k => k?.id === keyword.id);
      if (isActive) {
        return prev.filter(k => k?.id !== keyword.id);
      }
      return [...prev, keyword];
    });
  };

  // 날짜 포맷팅 함수
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const now = new Date();
  
    const diff = now - date;
    const minutes = Math.floor(diff / 60000);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
  
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);
  
    if (days < 1) {
      if (hours < 1) {
        return `${minutes} minutes ago`;
      }
      return `${hours} hours ago`;
    }
  
    if (date >= yesterday && date < today) {
      return 'Yesterday';
    }
  
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
    });
  };  

  // 페이지네이션된 뉴스 계산
  const paginatedNews = useMemo(() => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    return filteredNews.slice(startIndex, startIndex + itemsPerPage);
  }, [filteredNews, currentPage]);

  // 총 페이지 수 계산
  const totalPages = Math.ceil(filteredNews.length / itemsPerPage);

  // 페이지 변경 핸들
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // 뉴스 목록이 변경될 때마다 첫 페이지로 이동
  useEffect(() => {
    setCurrentPage(1);
  }, [filteredNews.length]);

  // 상수 정의
  const MAX_VISIBLE_KEYWORDS = 3;  // 최대 표시할 키워드 수

  const handleRefresh = async () => {
    if (loading) return;
    
    // 새로고침 시 캐시 삭제
    localStorage.removeItem(CACHE_KEY);
    
    try {
      await fetchNews();
    } catch (error) {
      console.error('뉴스 새로고침 중 오류:', error);
    }
  };

  const handleAnalyze = async (newsItem) => {
    try {
      setLoading(true);
      const summary = await analyzeNews(newsItem.content);
      
      // Firestore에 분석 결과 저장
      await addAnalysisHistory(user.uid, {
        newsTitle: newsItem.title,
        newsUrl: newsItem.url,
        summary: summary,
        timestamp: new Date()
      });

      // 분석 카운트 증가
      await incrementAiAnalysisCount(user.uid);
      
      setShowAnalysisModal(true);
      loadAnalysisHistory(); // 히스토리 새로고침
    } catch (error) {
      console.error('뉴스 분석 중 오류:', error);
      alert('뉴스 분석 중 오류가 발생했습니다.');
    } finally {
      setLoading(false);
    }
  };

  const loadAnalysisHistory = () => {
    try {
      const history = getAnalysisHistory();
      setAnalysisHistory(history);
    } catch (error) {
      console.error('분석 히스토리 로드 중 오류:', error);
    }
  };

  // 컴포넌트 마운트 시 히스토리 로드
  useEffect(() => {
    loadAnalysisHistory();
  }, []);

  const handleUpgrade = () => {
    navigate('/profile', { state: { activeTab: 'subscription' } });
  };

  const renderNavigation = () => (
    <NewsTabNavigation 
      activeTab={activeTab}
      onTabChange={setActiveTab}
      activeKeywords={activeKeywords}
      onKeywordModalOpen={() => setIsKeywordModalOpen(true)}
      onAnalysisClick={() => setShowAnalysisModal(true)}
      onHistoryClick={() => setShowHistoryModal(true)}
      hasHistory={analysisHistory.length > 0}
      isAnalysisLimitReached={isAnalysisLimitReached}
      onUpgrade={handleUpgrade}  // handleUpgrade 함수 전달
    />
  );

  return (
    <div className="min-h-screen bg-gray-50">
      <div className="max-w-4xl mx-auto p-4 sm:p-6">
        {renderNavigation()}
        
        {isKeywordModalOpen && (
          <KeywordFilterModal
            keywords={keywords.selected}
            activeKeywords={activeKeywords}
            onToggleKeyword={handleToggleKeyword}
            newsCountByKeyword={newsCountByKeyword}
            onClose={() => setIsKeywordModalOpen(false)}
          />
        )}
        
        {activeTab === 'latest' && (
          <>
            <div className="text-sm text-gray-500 mt-2 mb-4 flex items-center gap-2">
              <span>Updated: {lastUpdated}</span>
              <button
                onClick={handleRefresh}
                disabled={loading}
                className={`p-1.5 rounded-full hover:bg-gray-100 transition-colors ${
                  loading ? 'opacity-50 cursor-not-allowed' : ''
                }`}
                title="refresh"
              >
                <FaSync className={`w-4 h-4 ${loading ? 'animate-spin' : ''}`} />
              </button>
            </div>

            {initialLoading || loading ? (
              <div className="space-y-4">
                {[1, 2, 3].map((i) => (
                  <div key={i} className="bg-white rounded-lg shadow-sm p-4 animate-pulse">
                    <div className="h-4 bg-gray-200 rounded w-3/4 mb-3"></div>
                    <div className="h-3 bg-gray-200 rounded w-1/4"></div>
                  </div>
                ))}
              </div>
            ) : filteredNews.length === 0 ? (
              <EmptyState type={keywords.selected.length > 0 ? 'no-news' : 'no-keywords'} />
            ) : (
              <>
                <NewsList 
                  news={paginatedNews}
                  activeKeywords={activeKeywords}
                  formatDate={formatDate}
                />
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onPageChange={handlePageChange}
                />
              </>
            )}
          </>
        )}
        
        {activeTab === 'ai' && (
          <AINewsSection 
            news={news}
            formatDate={formatDate}
            showAnalysisModal={showAnalysisModal}
            setShowAnalysisModal={setShowAnalysisModal}
            showHistoryModal={showHistoryModal}
            setShowHistoryModal={setShowHistoryModal}
          />
        )}
      </div>
    </div>
  );
}

export default NewsFeed;