import React, { useState, useEffect } from 'react';
import { db, auth } from '../firebase';
import { 
  collection, 
  query, 
  orderBy, 
  limit, 
  startAfter, 
  getDocs 
} from 'firebase/firestore';
import TopicAnalysisResult from '../components/TopicAnalysisResult';
import { formatDate } from '../utils/dateUtils';
import { enrichDigestWithNewsLinks } from '../utils/newsDigestManager';
import { FaChevronRight } from 'react-icons/fa';
import TopicNewsModal from '../components/modals/TopicNewsModal';

function TodayAI() {
  const [digests, setDigests] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [lastDoc, setLastDoc] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const [selectedTopic, setSelectedTopic] = useState(null);

  const formatTimestamp = (timestamp) => {
    const date = new Date(parseInt(timestamp));
    return {
      date: date.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      }),
      time: date.toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit'
      })
    };
  };

  const fetchDigests = async () => {
    if (loading) return;
    
    console.log('fetchDigests 시작');
    setLoading(true);
    setError(null);
    
    try {
      const digestsRef = collection(db, 'news_digests');
      console.log('컬렉션 참조 생성');
      
      let digestQuery;
      if (lastDoc) {
        digestQuery = query(
          digestsRef,
          orderBy('__name__', 'desc'),
          startAfter(lastDoc),
          limit(10)
        );
      } else {
        digestQuery = query(
          digestsRef,
          orderBy('__name__', 'desc'),
          limit(10)
        );
      }

      console.log('쿼리 실행 시작');
      const snapshot = await getDocs(digestQuery);
      console.log('받은 문서 수:', snapshot.docs.length);

      if (snapshot.empty) {
        console.log('데이터가 없습니다');
        setHasMore(false);
        return;
      }

      const newDigests = await Promise.all(
        snapshot.docs.map(async doc => {
          const data = doc.data();
          console.log('문서 데이터:', doc.id, data);
          const digestData = {
            id: doc.id,
            ...data
          };
          return await enrichDigestWithNewsLinks(digestData);
        })
      );

      console.log('처리된 다이제스트:', newDigests);
      setDigests(prev => [...prev, ...newDigests]);
      
      if (snapshot.docs.length > 0) {
        setLastDoc(snapshot.docs[snapshot.docs.length - 1]);
      }

      setHasMore(snapshot.docs.length === 10);
    } catch (error) {
      console.error('다이제스트 로딩 중 상세 오류:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    console.log('TodayAI 컴포넌트 마운트');
    // 초기 데이터 로드
    fetchDigests();
    
    return () => {};
  }, []);

  return (
    <div className="max-w-4xl mx-auto p-4">
      <div className="mb-6">
        <h1 className="text-2xl font-bold">AI News Digests</h1>
      </div>

      {error && (
        <div className="mb-4 p-4 bg-red-100 text-red-700 rounded-lg">
          {error}
        </div>
      )}

      {loading ? (
        <div className="flex justify-center items-center py-8">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
        </div>
      ) : (
        <div className="space-y-8">
          {digests.map((digest) => {
            const topics = digest.data.topics || [];
            const keywords = digest.data.keywords || [];
            const timestamp = formatTimestamp(digest.id.split('_')[0]);
            
            return (
              <div key={digest.id} className="bg-white rounded-lg shadow-lg overflow-hidden">
                <div className="p-6">
                  <div className="text-sm text-gray-500 mb-4">
                    <time className="font-medium">{timestamp.date}</time>
                    <span className="mx-2">|</span>
                    <time>{timestamp.time}</time>
                  </div>

                  <div className="mb-6">
                    <div className="flex flex-wrap gap-2">
                      {keywords.map((keyword, idx) => (
                        <span key={idx} className="px-3 py-1 bg-blue-100 text-blue-800 rounded-full text-sm">
                          {keyword}
                        </span>
                      ))}
                    </div>
                  </div>

                  <div className="space-y-6">
                    {topics.map((topic, idx) => (
                      <div key={idx} className="border-t pt-4">
                        <div className="flex justify-between items-center mb-3">
                          <h3 className="text-lg font-medium">{topic.topic}</h3>
                          <button
                            onClick={() => setSelectedTopic(topic)}
                            className="text-sm text-gray-600 hover:text-gray-800 flex items-center gap-1"
                          >
                            More
                            <FaChevronRight className="w-3 h-3" />
                          </button>
                        </div>
                        <ul className="space-y-2">
                          {topic.news.slice(0, 3).map((item, newsIdx) => (
                            <li key={newsIdx} className="flex items-start">
                              <span className="text-gray-400 mr-2">•</span>
                              <a 
                                href={item.link} 
                                target="_blank" 
                                rel="noopener noreferrer"
                                className="text-blue-600 hover:underline hover:text-blue-800 transition-colors"
                              >
                                {item.title}
                              </a>
                            </li>
                          ))}
                        </ul>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}

      {digests.length > 0 && hasMore && (
        <div className="mt-6 text-center">
          <button
            onClick={() => fetchDigests()}
            className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors"
          >
            더 보기
          </button>
        </div>
      )}

      {selectedTopic && (
        <TopicNewsModal
          topic={selectedTopic.topic}
          newsList={selectedTopic.news}
          onClose={() => setSelectedTopic(null)}
        />
      )}
    </div>
  );
}

export default TodayAI; 