import React from 'react';
import { FaTimes, FaTrash } from 'react-icons/fa';
import { useAuth } from '../../../contexts/AuthContext';
import { deleteAnalysisHistory } from '../../../utils/userManager';

const AnalysisHistoryModal = ({ isOpen, onClose, history, onSelectHistory, currentAnalysis, onDeleteHistory }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
      <div className="bg-white rounded-lg w-full max-w-md">
        <div className="p-6">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-xl font-semibold">Analysis History</h2>
            <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
              <FaTimes />
            </button>
          </div>
          
          <div className="space-y-4">
            {history.map((item) => (
              <div key={item.timestamp} className="border rounded-lg p-4">
                <div className="flex justify-between items-start mb-2">
                  <div>
                    <div className="text-sm text-gray-600">
                      {new Date(item.timestamp).toLocaleString('en-US')}
                    </div>
                    <div className="text-sm text-gray-500">
                      {item.newsCount} news analyzed
                    </div>
                  </div>
                  <div className="flex gap-2">
                    <button
                      onClick={() => onSelectHistory(item)}
                      className="text-blue-500 hover:text-blue-600 text-sm"
                    >
                      View
                    </button>
                    <button
                      onClick={() => onDeleteHistory(item.timestamp)}
                      className="text-red-500 hover:text-red-600 text-sm"
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
          
          <div className="mt-4 text-sm text-gray-500 text-center">
            * History is stored locally on your device
          </div>
        </div>
        <div className="border-t p-4">
          <button
            onClick={onClose}
            className="w-full px-4 py-2 bg-gray-100 text-gray-700 rounded-lg hover:bg-gray-200 transition-colors"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default AnalysisHistoryModal; 