import React from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { FaCheckCircle } from 'react-icons/fa';
import { doc, updateDoc, serverTimestamp } from 'firebase/firestore';
import { db } from '../../firebase';

const PLANS = [
  {
    id: 'monthly',
    name: 'Monthly Subscription',
    price: 2,
    period: 'month',
    features: [
      'Register up to 50 keywords',
      '100 AI analyses per month',
      'Analyze up to 500 news articles'
    ]
  },
  {
    id: 'yearly',
    name: 'Yearly Subscription',
    price: 20,
    period: 'year',
    features: [
      'All features of the monthly plan',
      '16% discount'
    ]
  }
];

function SubscriptionPlans() {
  const { userData, user } = useAuth();
  const isPremium = userData?.tier === 'premium';
  
  const formatDate = (dateString) => {
    if (!dateString) return '-';
    return new Date(dateString).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };

  const handleSubscribe = async (planId) => {
    try {
      if (!user) {
        alert('Please log in.');
        return;
      }

      alert('The payment system is under development.\nEnjoy premium services for free until January 31, 2025.');
      
      const endDate = new Date('2025-01-31');
      const subscriptionData = {
        status: 'active',
        startDate: serverTimestamp(),
        endDate: endDate,
        paymentMethod: 'free_trial',
        cancelDate: null,
        plan: planId
      };
      
      const userRef = doc(db, 'users', user.uid);
      await updateDoc(userRef, {
        tier: 'premium',
        subscription: subscriptionData,
        updatedAt: serverTimestamp()
      });

      window.location.reload();
    } catch (error) {
      console.error('Error during subscription:', error);
      alert('An error occurred during the subscription process.');
    }
  };
  
  return (
    <div className="bg-white rounded-lg shadow-sm p-6">
      <h2 className="text-xl font-bold mb-6">Subscription Plans</h2>
      <div className="grid md:grid-cols-2 gap-6">
        {PLANS.map((plan) => (
          <div
            key={plan.id}
            className="border rounded-lg p-6 flex flex-col justify-between"
          >
            <div>
              <h3 className="text-xl font-bold mb-2">{plan.name}</h3>
              <div className="text-3xl font-bold mb-4">
                ${plan.price.toLocaleString()}
                <span className="text-sm text-gray-500">/{plan.period}</span>
              </div>
              <ul className="space-y-2 mb-6">
                {plan.features.map((feature, index) => (
                  <li key={index} className="flex items-center gap-2">
                    <FaCheckCircle className="text-green-500 flex-shrink-0" />
                    <span>{feature}</span>
                  </li>
                ))}
              </ul>
            </div>
            {/* CTA 버튼 */}
            <button
              onClick={() => handleSubscribe(plan.id)}
              disabled={isPremium}
              className={`w-full py-2 px-4 rounded-lg mt-4 ${
                isPremium
                  ? 'bg-gray-100 text-gray-500 cursor-not-allowed'
                  : 'bg-blue-500 text-white hover:bg-blue-600'
              }`}
            >
              {isPremium ? 'Already Subscribed' : 'Subscribe'}
            </button>
          </div>
        ))}
      </div>
    </div>
  );
}
  
export default SubscriptionPlans;
