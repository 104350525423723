import React, { useMemo, useEffect, useState } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { useSubscriptionLimits } from '../../hooks/useSubscriptionLimits';
import { FaCrown, FaCheckCircle } from 'react-icons/fa';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../firebase';

function SubscriptionStatus() {
  const { userData, user } = useAuth();
  const { limits, usage } = useSubscriptionLimits();
  const [keywordCount, setKeywordCount] = useState(0);

  const formatDate = (date) => {
    if (!date) return '-';
    
    // Firestore Timestamp인 경우
    if (date?.toDate) {
      date = date.toDate();
    }
    // ISO 문자열인 경우
    else if (typeof date === 'string') {
      date = new Date(date);
    }
    
    return date instanceof Date ? 
      date.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      }) : '-';
  };

  // keywords 컬렉션의 문서 개수 가져오기
  useEffect(() => {
    if (!user?.uid) return;

    const keywordsRef = collection(db, 'users', user.uid, 'keywords');
    getDocs(keywordsRef).then(snapshot => {
      setKeywordCount(snapshot.size);
    }).catch(error => {
      console.error('키워드 개수 조회 중 오류:', error);
    });
  }, [user?.uid]);

  return (
    <div className="bg-white rounded-lg shadow-sm p-6 mb-6">
      <div className="flex items-center gap-3 mb-4">
        <FaCrown className="w-6 h-6 text-blue-500" />
        <h2 className="text-xl font-bold">Subscription Status</h2>
      </div>

      <div className="space-y-4">
        <div className="grid grid-cols-2 gap-4">
          <div className="p-4 bg-gray-50 rounded-lg">
            <div className="text-sm text-gray-600">Current Tier</div>
            <div className="text-lg font-semibold">
              {userData?.tier === 'premium' ? 'Premium' : 'Free'}
            </div>
          </div>
          <div className="p-4 bg-gray-50 rounded-lg">
            <div className="text-sm text-gray-600">Expiration Date</div>
            <div className="text-lg font-semibold">
              {formatDate(userData?.subscription?.endDate || userData?.premiumUntil)}
            </div>
          </div>
        </div>

        <div className="space-y-2">
          <h3 className="font-semibold">Usage Status</h3>
          <div className="space-y-1">
            <div className="flex justify-between text-sm">
              <span>Keyword Registration</span>
              <span>{keywordCount} / {userData?.tier === 'premium' ? 50 : 3} items</span>
            </div>
            <div className="flex justify-between text-sm">
              <span>AI Analysis Count This Month</span>
              <span>{userData?.usage?.aiAnalysisCount || 0} / {userData?.tier === 'premium' ? 100 : 10} times</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SubscriptionStatus;
