// 새로 생성할 파일입니다.

// 로컬 스토리지 키 상수
export const ANALYSIS_HISTORY_KEY = 'analysisHistory';

// 분석 히스토리 저장
export const saveAnalysisHistory = (analysisData) => {
  try {
    const existingHistory = JSON.parse(localStorage.getItem(ANALYSIS_HISTORY_KEY) || '[]');
    const updatedHistory = [analysisData, ...existingHistory].slice(0, 3);
    localStorage.setItem(ANALYSIS_HISTORY_KEY, JSON.stringify(updatedHistory));
    return updatedHistory;
  } catch (error) {
    console.error('분석 히스토리 저장 중 오류:', error);
    return [];
  }
};

// 분석 히스토리 조회
export const getAnalysisHistory = () => {
  try {
    return JSON.parse(localStorage.getItem(ANALYSIS_HISTORY_KEY) || '[]');
  } catch (error) {
    console.error('분석 히스토리 로드 중 오류:', error);
    return [];
  }
};

// 분석 히스토리 삭제
export const deleteAnalysisHistory = (timestamp) => {
  try {
    const existingHistory = JSON.parse(localStorage.getItem(ANALYSIS_HISTORY_KEY) || '[]');
    const updatedHistory = existingHistory.filter(item => item.timestamp !== timestamp);
    localStorage.setItem(ANALYSIS_HISTORY_KEY, JSON.stringify(updatedHistory));
    return updatedHistory;
  } catch (error) {
    console.error('분석 히스토리 삭제 중 오류:', error);
    return [];
  }
}; 