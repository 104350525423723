import React, { useState } from 'react';
import { FaCrown, FaTimes } from 'react-icons/fa';

const SubscriptionModal = ({ onClose, onUpgrade }) => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 w-full max-w-md">
        <div className="flex justify-between items-center mb-4">
          <div className="flex items-center gap-2">
            <FaCrown className="text-yellow-500" />
            <h3 className="text-lg font-semibold">프리미엄 기능</h3>
          </div>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <FaTimes />
          </button>
        </div>
        <div className="mb-6">
          <p className="text-gray-600 mb-4">
            Use up all free AI analysis
          </p>
        </div>
        <div className="flex justify-end gap-2">
          <button
            onClick={onClose}
            className="px-4 py-2 text-gray-600 hover:bg-gray-100 rounded-lg transition-colors"
          >
            닫기
          </button>
          <button
            onClick={() => {
              onClose();
              onUpgrade();
            }}
            className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors"
          >
            프리미엄 구독
          </button>
        </div>
      </div>
    </div>
  );
};

function NewsTabNavigation({ 
  activeTab, 
  onTabChange, 
  activeKeywords, 
  onKeywordModalOpen, 
  onAnalysisClick, 
  onHistoryClick, 
  hasHistory, 
  isAnalysisLimitReached,
  onUpgrade 
}) {
  const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);

  return (
    <div className="flex items-center justify-between">
      <div className="flex gap-4">
        <button
          onClick={() => onTabChange('latest')}
          className={`text-lg font-semibold pb-2 border-b-2 ${
            activeTab === 'latest'
              ? 'text-blue-600 border-blue-600'
              : 'text-gray-500 border-transparent hover:text-gray-700'
          }`}
        >
          News
        </button>
        <button
          onClick={() => onTabChange('ai')}
          className={`text-lg font-semibold pb-2 border-b-2 ${
            activeTab === 'ai'
              ? 'text-blue-600 border-blue-600'
              : 'text-gray-500 border-transparent hover:text-gray-700'
          }`}
        >
          AI summary
        </button>
      </div>
      
      {activeTab === 'latest' ? (
        <button
          onClick={onKeywordModalOpen}
          className="flex items-center gap-2 px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors"
          >
          <span>Keywords</span>
          <span className="bg-blue-400 px-2 py-0.5 rounded-full text-sm">
            {activeKeywords.length}
          </span>
        </button>
      ) : (
        <div className="flex gap-2">
          <button
            onClick={onHistoryClick}
            className="px-4 py-2 text-gray-600 border border-gray-300 rounded-lg transition-colors"
            >
            History
          </button>
          <button
            onClick={() => {
              if (isAnalysisLimitReached) {
                setShowSubscriptionModal(true);
              } else {
                onAnalysisClick();
              }
            }}
            className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors"
            >
            AI Analyze
          </button>
        </div>
      )}

      {showSubscriptionModal && (
        <SubscriptionModal
          onClose={() => setShowSubscriptionModal(false)}
          onUpgrade={onUpgrade}
        />
      )}
    </div>
  );
}

export default NewsTabNavigation; 