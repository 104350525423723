import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { FaGoogle } from 'react-icons/fa';
import KeywordManager from '../components/keywords/KeywordManager';
import { getKeywords, addKeyword, updateKeywordCount } from '../utils/userManager';
import { useSubscriptionLimits } from '../hooks/useSubscriptionLimits';

function KeywordManagementPage() {
  const { user, loginWithGoogle, logout } = useAuth();
  const [keywords, setKeywords] = useState({ selected: [], recommended: [] });
  const { isWithinLimits, limits } = useSubscriptionLimits();
  const [error, setError] = useState(null);

  useEffect(() => {
    const loadUserKeywords = async () => {
      if (user) {
        try {
          const userKeywords = await getKeywords(user.uid);
          setKeywords({
            selected: userKeywords || [],
            recommended: []
          });
        } catch (error) {
          console.error('키워드 로드 중 오류:', error);
          setError('키워드를 불러오는 중 오류가 발생했습니다.');
        }
      } else {
        setKeywords({ selected: [], recommended: [] });
      }
    };
    
    loadUserKeywords();
  }, [user]);

  if (!user) {
    return (
      <div className="max-w-4xl mx-auto p-4 text-center">
        <div className="bg-white rounded-lg shadow-sm border p-8 mt-8">
          <h1 className="text-2xl font-bold text-gray-800 mb-4">프워드 관리</h1>
          <p className="text-gray-600 mb-6">
            Please login to manage keywords
          </p>
          <button
            onClick={loginWithGoogle}
            className="flex items-center gap-2 px-6 py-3 bg-white text-gray-700 rounded-lg border hover:bg-gray-50 transition-colors mx-auto"
          >
            <FaGoogle className="text-red-500" />
            Google Login
          </button>
        </div>
      </div>
    );
  }

  const handleAddKeyword = (newKeyword) => {
    if (!isWithinLimits.keywords(keywords.selected.length)) {
      alert(`키워드는 최대 ${limits.keywords}개까지만 등록할 수 있습니다. 프리미엄으로 업그레이드하세요.`);
      return;
    }
    
    setKeywords(prev => ({
      ...prev,
      selected: [...prev.selected, newKeyword]
    }));
  };

  const handleSave = async () => {
    try {
      if (!isWithinLimits.keywords(keywords.selected.length)) {
        throw new Error(`키워드는 최대 ${limits.keywords}개까지만 저장할 수 있습니다.`);
      }

      await Promise.all(keywords.selected.map(keyword => 
        addKeyword(user.uid, keyword)
      ));
      
      await updateKeywordCount(user.uid, keywords.selected.length);
      
      alert('키워드가 저장되었습니다.');
    } catch (error) {
      console.error('키워드 저장 중 오류:', error);
      setError(error.message);
    }
  };

  return (
    <div className="max-w-4xl mx-auto p-4">
      {error && (
        <div className="bg-red-50 text-red-600 p-4 rounded-lg mb-4">
          {error}
        </div>
      )}
      
      <div className="bg-white rounded-lg shadow-sm p-6 mb-4">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-bold">등록된 키워드</h2>
          <div className="text-sm text-gray-500">
            {keywords.selected.length} / {limits.keywords}개
          </div>
        </div>
        
        <KeywordManager
          keywords={keywords}
          onAddKeyword={handleAddKeyword}
          onUpdateKeyword={(keyword) => {
            setKeywords(prev => ({
              ...prev,
              selected: prev.selected.map(k => k.id === keyword.id ? keyword : k)
            }));
          }}
          onRemoveKeyword={(id) => {
            setKeywords(prev => ({
              ...prev,
              selected: prev.selected.filter(k => k.id !== id)
            }));
          }}
          onSave={handleSave}
          onClearAll={() => {
            if (window.confirm('모든 키워드를 삭제하시겠습니까?')) {
              setKeywords({ selected: [], recommended: [] });
            }
          }}
        />
      </div>

      <div className="text-center">
        <button
          onClick={logout}
          className="px-6 py-3 bg-red-500 text-white rounded-lg hover:bg-red-600 transition-colors"
        >
          로그아웃
        </button>
      </div>
    </div>
  );
}

export default KeywordManagementPage; 