import OpenAI from 'openai';

const NEWS_ASSISTANT_ID = 'asst_ifKS7QAjaQwx4oCgxzcIuiOS';

const openai = new OpenAI({
  apiKey: process.env.REACT_APP_OPENAI_API_KEY,
  dangerouslyAllowBrowser: true,
});

function logRequestResponse(endpoint, request, response) {
  console.log(`=== [News Analysis API Call: ${endpoint}] ===`);
  console.log('Request:', JSON.stringify(request, null, 2));
  console.log('Response:', JSON.stringify(response, null, 2));
  console.log('================================');
}

const ANALYZE_NEWS_URL = process.env.REACT_APP_ANALYZE_NEWS_URL;

// 분석 범위 설정
export const ANALYSIS_OPTIONS = {
  RECENT_100: {
    maxNews: 100,
    chunkSize: 50,
    label: 'Recent 100',
    requiresAuth: false
  },
  RECENT_300: {
    maxNews: 300,
    chunkSize: 60,
    label: 'Recent 300',
    requiresAuth: true
  },
  RECENT_500: {
    maxNews: 500,
    chunkSize: 100,
    label: 'Recent 500',
    requiresAuth: true
  }
};

export async function analyzeNewsTopics(newsItems, analysisOption = 'RECENT_100', onProgress) {
  try {
    const titles = newsItems.map(item => item.title);
    const option = ANALYSIS_OPTIONS[analysisOption] || ANALYSIS_OPTIONS.RECENT_100;
    const effectiveNewsItems = newsItems.slice(0, option.maxNews);

    const requestData = {
      newsItems: effectiveNewsItems,
      analysisOption: analysisOption
    };

    const response = await fetch(ANALYZE_NEWS_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestData)
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const reader = response.body.getReader();
    let analysisResult = null;
    let analysisInfo = null;
    let buffer = '';

    while (true) {
      const { done, value } = await reader.read();
      if (done) break;

      // 버퍼에 새로운 데이터 추가
      buffer += new TextDecoder().decode(value);
      
      // 완전한 라인들을 찾아서 처리
      const lines = buffer.split('\r\n');
      // 마지막 라인은 불완전할 수 있으므로 버퍼에 남김
      buffer = lines.pop() || '';

      for (const line of lines) {
        if (!line) continue;
        
        try {
          const data = JSON.parse(line);
          
          if (data.type === 'progress' && onProgress) {
            onProgress(data.progress);
          } else if (data.type === 'complete') {
            analysisResult = data.analysis;
            analysisInfo = data.analysisInfo;
          }
        } catch (error) {
          console.warn('Stream data parsing warning:', error);
          continue;
        }
      }
    }

    // 버퍼에 남은 마지막 데이터 처리
    if (buffer) {
      try {
        const data = JSON.parse(buffer);
        if (data.type === 'complete') {
          analysisResult = data.analysis;
          analysisInfo = data.analysisInfo;
        }
      } catch (error) {
        console.warn('Final buffer parsing warning:', error);
      }
    }

    if (!analysisResult) {
      throw new Error('분석 결과를 받지 못했습니다.');
    }

    return {
      analysis: analysisResult,
      analysisInfo: analysisInfo
    };
  } catch (error) {
    console.error('뉴스 분석 중 오류:', error);
    throw error;
  }
}

// OpenAI 관련 함수들 (필요한 경우)
export async function createThread() {
  return await openai.beta.threads.create();
}

export async function createMessage(threadId, content) {
  return await openai.beta.threads.messages.create(threadId, {
    role: 'user',
    content: content
  });
}

export async function runAssistant(threadId) {
  return await openai.beta.threads.runs.create(
    threadId,
    { assistant_id: NEWS_ASSISTANT_ID }
  );
}

export async function checkRunStatus(threadId, runId) {
  return await openai.beta.threads.runs.retrieve(threadId, runId);
}

export async function getMessages(threadId) {
  return await openai.beta.threads.messages.list(threadId);
} 