import { createContext, useContext, useEffect, useState } from 'react';
import { auth, db, googleProvider } from '../firebase';
import { 
  onAuthStateChanged,
  signInWithPopup,
  signOut
} from 'firebase/auth';
import { 
  createNewUser, 
  getUserData, 
  updateLastLogin,
  addNewsHistory,
  getNewsHistory,
  addKeyword,
  getKeywords,
  checkAndResetMonthlyUsage
} from '../utils/userManager';
import { getAnalysisHistory } from '../utils/localStorageManager';
import { doc, updateDoc, serverTimestamp } from 'firebase/firestore';
import { migrateUserSubscription } from '../utils/subscriptionManager';

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [user, setUser] = useState(null);
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Google 로그인
  const loginWithGoogle = async () => {
    try {
      // 이미 진행 중인 인증 세션이 있는지 확인
      if (auth.currentUser) {
        console.log('이미 로그인되어 있습니다.');
        return auth.currentUser;
      }

      const result = await signInWithPopup(auth, googleProvider);
      
      const existingUserData = await getUserData(result.user.uid);
      
      if (!existingUserData) {
        const newUserData = await createNewUser(result.user);
        setUserData(newUserData);
      } else {
        await updateLastLogin(result.user.uid);
        setUserData(existingUserData);
      }
      
      return result.user;
    } catch (error) {
      if (error.code === 'auth/popup-closed-by-user' || 
          error.code === 'auth/cancelled-popup-request' ||
          error.code === 'auth/popup-blocked') {
        console.log('로그인 팝업이 차단되었거나 취소되었습니다.');
        return null;
      }
      console.error('Google 로그인 오류:', error);
      throw error;
    }
  };

  // 로그아웃
  const logout = async () => {
    await signOut(auth);
    setUserData(null);
  };

  const checkSubscriptionStatus = async (userData) => {
    if (!userData?.subscription?.endDate) return;
    
    const now = new Date();
    const endDate = userData.subscription.endDate.toDate();
    
    if (now > endDate && userData.tier === 'premium') {
      const userRef = doc(db, 'users', userData.uid);
      await updateDoc(userRef, {
        tier: 'free',
        'subscription.status': 'expired',
        updatedAt: serverTimestamp()
      });
      return true;
    }
    return false;
  };

  // 사용자 인증 상태 모니터링
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      try {
        setUser(user);
        if (user) {
          const data = await getUserData(user.uid);
          if (!data) {
            const newUserData = await createNewUser(user);
            setUserData(newUserData);
          } else {
            // 구독 데이터 마이그레이션 수행
            await migrateUserSubscription(user.uid);
            await checkAndResetMonthlyUsage(user.uid);
            await checkSubscriptionStatus(data);
            const updatedData = await getUserData(user.uid);
            setUserData(updatedData);
            await updateLastLogin(user.uid);
          }
        } else {
          setUserData(null);
        }
      } catch (error) {
        console.error("Auth state change error:", error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    });

    return unsubscribe;
  }, []);

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="text-red-500">
          오류가 발생했습니다: {error}
        </div>
      </div>
    );
  }

  const value = {
    user,
    userData: {
      ...userData,
      usage: {
        ...userData?.usage,
        historyCount: getAnalysisHistory().length
      }
    },
    loginWithGoogle,
    logout,
    isAdmin: userData?.tier === 'admin',
    isPremium: userData?.tier === 'premium',
    addNewsHistory: (newsData) => addNewsHistory(user.uid, newsData),
    getNewsHistory: () => getNewsHistory(user.uid),
    addKeyword: (keywordData) => addKeyword(user.uid, keywordData),
    getKeywords: () => getKeywords(user.uid)
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
} 