import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaExclamationTriangle, FaHome } from 'react-icons/fa';

function NotFoundPage() {
  const navigate = useNavigate();

  // 5초 후 자동으로 메인 페이지로 이동
  useEffect(() => {
    const timer = setTimeout(() => {
      navigate('/');
    }, 5000);

    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="max-w-md w-full p-6 bg-white rounded-lg shadow-lg text-center">
        <FaExclamationTriangle className="mx-auto text-yellow-500 text-5xl mb-4" />
        <h1 className="text-4xl font-bold text-gray-800 mb-4">404</h1>
        <p className="text-xl text-gray-600 mb-6">
          Can't find this page
        </p>
        <p className="text-gray-500 mb-8">
          Landing to mainpage in 5 seconds...
        </p>
        <button
          onClick={() => navigate('/')}
          className="inline-flex items-center justify-center gap-2 px-6 py-3 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors"
        >
          <FaHome />
          Go to mainpage
        </button>
      </div>
    </div>
  );
}

export default NotFoundPage; 