import { useAuth } from '../contexts/AuthContext';
import { getAnalysisHistory } from '../utils/localStorageManager';

const TIER_LIMITS = {
  free: {
    keywords: 3,
    analysisPerMonth: 10,
    maxNewsPerAnalysis: 100
  },
  premium: {
    keywords: 50,
    analysisPerMonth: 100,
    maxNewsPerAnalysis: 500
  },
  admin: {
    keywords: Infinity,
    analysisPerMonth: Infinity,
    maxNewsPerAnalysis: Infinity
  }
};

export function useSubscriptionLimits() {
  const { userData } = useAuth();
  const tier = userData?.tier || 'free';
  const limits = TIER_LIMITS[tier];
  
  const isWithinLimits = {
    keywords: (count) => count <= limits.keywords,
    analysis: (count) => {
      const monthlyCount = userData?.usage?.aiAnalysisCount || 0;
      return monthlyCount < limits.analysisPerMonth;
    },
    newsPerAnalysis: (count) => count <= limits.maxNewsPerAnalysis
  };

  return {
    limits,
    isWithinLimits,
    tier,
    usage: {
      ...userData?.usage,
      historyCount: getAnalysisHistory().length
    }
  };
} 