import { db } from '../firebase';
import { collection, query, where, getDocs } from 'firebase/firestore';

export const enrichDigestWithNewsLinks = async (digest) => {
  try {
    // data 필드의 JSON 문자열을 파싱
    let analysisResult;
    try {
      analysisResult = typeof digest.data === 'string' 
        ? JSON.parse(digest.data)
        : digest.data;
    } catch (error) {
      console.error('JSON 파싱 오류:', error);
      return digest;
    }

    return {
      id: digest.id,
      data: analysisResult
    };
  } catch (error) {
    console.error('데이터 처리 중 오류:', error);
    return digest;
  }
}; 