import ReactGA from 'react-ga4';

// GA 초기화
export const initGA = () => {
  ReactGA.initialize('G-EVK5B85GPM');
};

// 페이지뷰 추적
export const logPageView = () => {
  ReactGA.send({ hitType: "pageview", page: window.location.pathname });
};

// 뉴스 분석 이벤트
export const logNewsAnalysis = (newsCount, option) => {
  ReactGA.event({
    category: 'News',
    action: 'Analyze',
    label: option,
    value: newsCount
  });
};

// 키워드 관리 이벤트
export const logKeywordAction = (action, keywordCount) => {
  ReactGA.event({
    category: 'Keywords',
    action: action,
    value: keywordCount
  });
};

// 로그인 이벤트
export const logLogin = (method) => {
  ReactGA.event({
    category: 'User',
    action: 'Login',
    label: method
  });
};

// 구독 관련 이벤트
export const logSubscriptionAction = (action) => {
  ReactGA.event({
    category: 'Subscription',
    action: action
  });
};

// 뉴스 상호작용 이벤트
export const logNewsInteraction = (action, newsTitle) => {
  ReactGA.event({
    category: 'News',
    action: action,
    label: newsTitle
  });
}; 