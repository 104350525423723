import React, { useState, useEffect } from 'react';
import { FaPlus, FaTimes, FaGlobe, FaEdit, FaTrash, FaSave, FaCrown } from 'react-icons/fa';
import { useSubscriptionLimits } from '../../hooks/useSubscriptionLimits';
import { logKeywordAction } from '../../utils/analytics';

// AddKeywordModal 컴포넌트
const AddKeywordModal = ({ onClose, onAddKeyword }) => {
  const [newKeyword, setNewKeyword] = useState('');

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 w-full max-w-md">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-lg font-semibold">Add new keywrd</h3>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <FaTimes />
          </button>
        </div>
        <input
          type="text"
          value={newKeyword}
          onChange={(e) => setNewKeyword(e.target.value)}
          className="w-full border rounded-lg px-4 py-2 mb-4 focus:outline-none focus:ring-2 focus:ring-blue-500"
          placeholder="Enter keyword"
          autoFocus
          onKeyPress={(e) => {
            if (e.key === 'Enter' && newKeyword.trim()) {
              onAddKeyword({
                id: Date.now().toString(),
                text: newKeyword.trim(),
                country: /^[A-Za-z\s]*$/.test(newKeyword) ? 'US' : 'KR'
              });
              setNewKeyword('');
              onClose();
            }
          }}
        />
        <div className="flex justify-end gap-2">
          <button
            onClick={onClose}
            className="px-4 py-2 text-gray-600 hover:bg-gray-100 rounded-lg transition-colors"
          >
            Cancel
          </button>
          <button
            onClick={() => {
              if (newKeyword.trim()) {
                onAddKeyword({
                  id: Date.now().toString(),
                  text: newKeyword.trim(),
                  country: /^[A-Za-z\s]*$/.test(newKeyword) ? 'US' : 'KR'
                });
                setNewKeyword('');
                onClose();
              }
            }}
            className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors"
          >
            추가
          </button>
        </div>
      </div>
    </div>
  );
};

// EditKeywordModal 컴포넌트
const EditKeywordModal = ({ keyword, onClose, onUpdate }) => {
  const [editedText, setEditedText] = useState(keyword?.text || '');

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 w-full max-w-md">
        <h3 className="text-lg font-semibold mb-4">키워드 수정</h3>
        <input
          type="text"
          value={editedText}
          onChange={(e) => setEditedText(e.target.value)}
          className="w-full border rounded-lg px-4 py-2 mb-4"
          placeholder="Enter keyword"
          autoFocus
        />
        <div className="flex justify-end gap-2">
          <button onClick={onClose} className="px-4 py-2 text-gray-600">
          Cancel
          </button>
          <button
            onClick={() => {
              if (editedText.trim()) {
                onUpdate({ ...keyword, text: editedText.trim() });
                onClose();
              }
            }}
            className="px-4 py-2 bg-blue-500 text-white rounded-lg"
          >
            수정
          </button>
        </div>
      </div>
    </div>
  );
};

// 구독 안내 모달 컴포넌트 추가
const SubscriptionModal = ({ onClose, onUpgrade }) => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 w-full max-w-md">
        <div className="flex justify-between items-center mb-4">
          <div className="flex items-center gap-2">
            <FaCrown className="text-yellow-500" />
            <h3 className="text-lg font-semibold">Premium</h3>
          </div>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <FaTimes />
          </button>
        </div>
        <div className="mb-6">
          <p className="text-gray-600 mb-4">
            Free tier can use 3 keywords
          </p>
        </div>
        <div className="flex justify-end gap-2">
          <button
            onClick={onClose}
            className="px-4 py-2 text-gray-600 hover:bg-gray-100 rounded-lg transition-colors"
          >
            Close
          </button>
          <button
            onClick={() => {
              onClose();
              onUpgrade();
            }}
            className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors"
          >
            Premium
          </button>
        </div>
      </div>
    </div>
  );
};

function KeywordChip({ keyword, onRemove }) {
  return (
    <div className="inline-flex items-center gap-2 px-3 py-1.5 bg-gray-100 rounded-full text-sm">
      <span>{keyword.text}</span>
      <button
        onClick={() => onRemove(keyword.id)}
        className="text-red-500 hover:text-red-600 transition-colors"
        aria-label="키워드 삭제"
      >
        <FaTrash className="w-3.5 h-3.5" />
      </button>
    </div>
  );
}

function KeywordManager({ keywords, onAddKeyword, onRemoveKeyword, onSave, onClearAll, hasChanges }) {
  const [newKeyword, setNewKeyword] = useState('');
  
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!newKeyword.trim()) return;
    
    onAddKeyword({ text: newKeyword.trim() });
    setNewKeyword('');
  };

  return (
    <div className="space-y-4">
      <form onSubmit={handleSubmit} className="flex gap-2">
        <input
          type="text"
          value={newKeyword}
          onChange={(e) => setNewKeyword(e.target.value)}
          placeholder="Input new keyword"
          className="flex-1 px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
        <button
          type="submit"
          className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors"
        >
          Add
        </button>
      </form>

      <div className="flex flex-wrap gap-2">
        {keywords.selected.map(keyword => (
          <KeywordChip
            key={keyword.id}
            keyword={keyword}
            onRemove={onRemoveKeyword}
          />
        ))}
      </div>

      <div className="flex justify-between pt-4">
        <button
          onClick={onClearAll}
          className="px-4 py-2 text-red-500 hover:text-red-600 transition-colors"
        >
          Delete all
        </button>
        <button
          onClick={onSave}
          disabled={!hasChanges}
          className={`px-4 py-2 rounded-lg ${
            hasChanges
              ? 'bg-blue-500 text-white hover:bg-blue-600'
              : 'bg-gray-200 text-gray-500 cursor-not-allowed'
          } transition-colors`}
        >
          Save
        </button>
      </div>
    </div>
  );
}

export default KeywordManager; 