import { useNavigate } from 'react-router-dom';
import { FaNewspaper } from 'react-icons/fa';

const EmptyState = ({ type }) => {
  const navigate = useNavigate();

  if (type === 'no-news') {
    return (
      <div className="text-center py-10">
        <div className="text-gray-500 mb-2">
          Rss Feed Issue
        </div>
        <div className="text-sm text-gray-400">
          Please retry in a second
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center justify-center py-12">
      <FaNewspaper className="w-16 h-16 text-gray-400 mb-4" />
      <h3 className="text-lg font-medium text-gray-900 mb-2">
        Add your keywords
      </h3>
      <p className="text-sm text-gray-500 text-center mb-4">
        We provide news about your keywords
      </p>
      <button
        onClick={() => navigate('/profile')}
        className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
      >
        Manage keywords
      </button>
    </div>
  );
};

export default EmptyState; 