import { useAuth } from '../contexts/AuthContext';
import NewsFeed from './NewsFeed';
import { FaGoogle } from 'react-icons/fa';

function MyFeed() {
  const { user, loginWithGoogle } = useAuth();

  if (!user) {
    return (
      <div className="max-w-4xl mx-auto p-4 text-center">
        <div className="bg-white rounded-lg shadow-sm border p-8 mt-8">
          <h1 className="text-2xl font-bold text-gray-800 mb-4">My Feed</h1>
          <p className="text-gray-600 mb-6">
            Please login to use your news feed
          </p>
          <button
            onClick={loginWithGoogle}
            className="flex items-center gap-2 px-6 py-3 bg-white text-gray-700 rounded-lg border hover:bg-gray-50 transition-colors mx-auto"
          >
            <FaGoogle className="text-red-500" />
            Google Login
          </button>
        </div>
      </div>
    );
  }

  return <NewsFeed />;
}

export default MyFeed;
