import { useAuth } from '../../contexts/AuthContext';
import { Link } from 'react-router-dom';
import { FaUser, FaSignOutAlt } from 'react-icons/fa';
import { logLogin } from '../../utils/analytics';

function LoginButton() {
  const { user, loginWithGoogle, logout } = useAuth();

  const handleLogout = async () => {
    if (window.confirm('로그아웃 하시겠습니까?')) {
      logLogin('Logout');
      await logout();
    }
  };

  if (user) {
    return (
      <div className="flex items-center gap-4">
        <Link
          to="/profile"
          className="flex items-center gap-2 text-gray-600 hover:text-blue-600"
        >
          <FaUser className="w-4 h-4" />
          <span>Profile</span>
        </Link>
        <button
          onClick={handleLogout}
          className="flex items-center gap-2 text-gray-600 hover:text-red-600"
        >
          <FaSignOutAlt className="w-4 h-4" />
          <span>Logout</span>
        </button>
      </div>
    );
  }

  return (
    <button
      onClick={loginWithGoogle}
      className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors"
    >
      Login
    </button>
  );
}

export default LoginButton; 