import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import Header from './components/Header';
import TodayAI from './pages/TodayAI';
import MyFeed from './pages/MyFeed';
import KeywordManagementPage from './pages/KeywordManagementPage';
import ProfilePage from './pages/ProfilePage';
import { initGA, logPageView } from './utils/analytics';
import { useEffect } from 'react';
import NotFoundPage from './pages/NotFoundPage';

function App() {
  useEffect(() => {
    initGA();
    logPageView();
  }, []);

  return (
    <AuthProvider>
      <Router>
        <div className="min-h-screen bg-gray-50">
          <Header />
          <Routes>
            <Route path="/" element={<TodayAI />} />
            <Route path="/feed" element={<MyFeed />} />
            <Route path="/keywords" element={<KeywordManagementPage />} />
            <Route path="/profile" element={<ProfilePage />} />
            <Route path="/404" element={<NotFoundPage />} />
            <Route path="*" element={<Navigate to="/404" replace />} />
          </Routes>
        </div>
      </Router>
    </AuthProvider>
  );
}

export default App; 